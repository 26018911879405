<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Booking
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Booking</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50" v-show="showTable">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <div class="float-end col-6" v-show="showTable">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                      <div class="float-end col-6" v-show="showForm">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="back"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <section id="ajax-datatable" v-show="showTable">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-datatable">
                            <table
                              class="dt-column-search table table-responsive"
                            >
                              <thead>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-show="list.length > 0" v-for="li in list" :key="li.id">
                                  <td>{{ li.booking_id }}</td>
                                  <td>{{ li.booking_date }}</td>
                                  <td>{{ li.check_in_date }}</td>
                                  <td>{{ li.check_out_date }}</td>
                                  <td>{{ li.amount }}</td>
                                  <td>{{ li.discount_amount }}</td>
                                  <td>{{ li.coupon_code }}</td>
                                  <td>{{ li.GST_amount }}</td>
                                  <td>{{ li.total_amount }}</td>
                                  <td>{{ li.final_amount }}</td>
                                  <td>{{ li.payment_mode.method }}</td>
                                  <td>{{ li.type }}</td>
                                  <td :class="recordId === li.id ? `statusZindex` : ``">
                                    <span class="status-badge badge " @click="clickStatus(li.id)"
                                      :class="getStatus(li.status)[0]">
                                      {{ getStatus(li.status)[1] }}
                                    </span>
                                    <div class="statusDiv" v-show="recordId === li.id ? isActive : ``">
                                      <ul>
                                        <li @click="clickLi(0, li.id)">Progress</li>
                                        <li @click="clickLi(1, li.id)">Success</li>
                                        <li @click="clickLi(2, li.id)">Pending</li>
                                        <li @click="clickLi(3, li,id)">Cancel</li>
                                        <li @click="clickLi(4, li,id)">Failed</li>
                                      </ul>
                                      <div class="card" v-show="loader">
                                        <div class="card-body">
                                            <div class="spinner-grow text-primary me-1" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{{ li.booking_cancel }}</td>
                                  <td>{{ li.total_adult }}</td>
                                  <td>{{ li.total_child }}</td>
                                  
                                  <td class="d-flex">
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-danger rounded"
                                          @click="deleteVilla(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="trash-2" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div 
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div v-if="li.status != 1"
                                          class="avatar bg-light-primary rounded"
                                          @click="edit(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="edit" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-show="list.length === 0">
                                  <td colspan="16" class="text-center">No Data Found</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </tfoot>
                            </table>
                            <div class="row d-flex">
                              <div class="col-12 d-flex">
                                <div class="col-6 showing_entry">
                                  Showing {{ paginationData.from }} to
                                  {{ paginationData.to }} of
                                  {{ paginationData.total }} entries
                                </div>
                                <div class="col-6">
                                  <Pagination
                                    :data="paginationData"
                                    @pagination-change-page="getResults"
                                    show-disabled="true"
                                    limit="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section v-show="showForm" style="order: 1">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <form
                            class="add-new-user pt-0"
                            method="post"
                            @submit.prevent="submitForm()"
                          >
                            <input type="hidden" name="id" :v-modal="form.id" />
                            <div class="flex-grow-1">
                              <div class="p-1 sectionBlock" id="section-block">
                                <div class="row">
                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label class="form-label" for="basic-addon-name">Check in - out date</label>
                                      <Datepicker 
                                        type="date"
                                        @closed="dateChange()"
                                        :min="new Date().toISOString().substr(0, 10)"
                                        v-model="form.rangeDate" 
                                        format="dd-MM-yyyy"
                                        value-format="dd-MM-yyyy"
                                        :min-date='new Date()'
                                        modelAuto range multiCalendars multiCalendarsSolo />
                                      <div class="invalid-feedback fw-bold fst-italic" :style="{display: checkInDateError}" > Please enter checkin checkout Date. </div>
                                    </div>
                                    <div class="mb-1 border  p-1 villaCls" style="background-color: #fcfcfc;">
                                      <h4>Villa Type & No of Villa</h4>
                                      
                                      <div class="px-1" v-for="villa, index in searchVilla" :key="villa.id">
                                        <div class="row" style="min-height: 10px">
                                          <div class="fw-bold fst-italic text-primary">Adult Limit {{villa.allow_adult}}, Extra charge per person <b>100</b> upto {{ villa.max_adult_limit }} Adult</div>
                                        </div>
                                        <div class="row m-1 border-bottom" style="min-height: 38px">
                                          <div class="col-4 align-items-center d-flex form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" 
                                              :id="`inlineCheckbox`+index" name="villa_type_id" 
                                              v-model="villa.villaTypeId" :value="villa.villaTypeId" 
                                              @change="checkChange($event, villa.villaTypeId)" />&nbsp;&nbsp;
                                              <label class="form-check-label" :for="`inlineCheckbox`+index">{{villa.villa_name}}</label>
                                          </div>
                                          <div class="col-4">
                                            <select v-show="show_villa[villa.villaTypeId]">
                                              <option v-for="vl, ind in villa.villa_quantity" :value="ind+1" :key="vl">{{ ind+1 }}</option>
                                            </select>
                                          </div>
                                          <!-- <div class="col-3 align-items-center d-flex" >
                                            <div class="fw-bold fst-italic error" v-show="showVillaError[villa.villaTypeId]"> Enter No. of Villa within {{ villa.villa_quantity }}. </div>
                                          </div> -->
                                        </div>
                                      </div>
                                      <!-- <div class="fw-bold fst-italic text-primary" v-html="sentence"> </div>
                                      <div class="px-1" v-for="villa, index in villaStore" :key="villa.id">
                                        <div class="row m-1 border-bottom" style="min-height: 38px">
                                          <div class="col-4 align-items-center d-flex form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" :id="`inlineCheckbox`+index" name="villa_type_id" v-model="villa_type_id[villa.id]" :value="villa.id" @change="checkChange($event, villa.id)" />&nbsp;&nbsp;
                                            <label class="form-check-label" :for="`inlineCheckbox`+index">{{villa.text}}</label>
                                          </div>
                                          <div class="col-4">
                                            <input type="number" class="form-control" name="total_villa" :id="`total_villa`+index" v-model="form.total_villa[villa.id]" v-show="show_villa[villa.id]" placeholder="No. of Villa"
                                            @keyup="[form.total_villa[villa.id].toString().length > 0
                                                ? [(showVillaError[villa.id] = false), (disabled = false)]
                                                : (showVillaError[villa.id] = true)
                                            ,villaNumber($event)]" />
                                          </div>
                                          <div class="col-3 align-items-center d-flex" >
                                            <div class="fw-bold fst-italic error" v-show="showVillaError[villa.id]"> Enter No. of Villa. </div>
                                          </div>
                                          commented <div class="fw-bold fst-italic error"> <small>Adult Limit <b>6</b>, Extra charge <b>1000</b> for max limit to 9 Adult</small> </div> 
                                        </div>
                                      </div> -->
                                    </div>
                                    <div class="mb-1">
                                      <div class="row">
                                        <div class="col-6">
                                          <label class="form-label" for="basic-addon-name">Total Adult</label>
                                          <input
                                              type="number" ref="total_adult" class="form-control" name="total_adult" v-model="form.total_adult" id="total_adult" placeholder="Total Adult" @keyup="
                                              form.total_adult.toString().length > 0
                                                  ? [(totalAdultError = 'none'), (disabled = false), villaNumber()]
                                                  : (totalAdultError = 'block')
                                              "
                                              />
                                          <div class="invalid-feedback fw-bold fst-italic" :style="{display: totalAdultError}" > Please enter Total Adult. </div>
                                        </div>
                                        <div class="col-6">
                                          <label class="form-label" for="basic-addon-name">Total Child</label>
                                          <input type="number" class="form-control" name="total_child" v-model="form.total_child" id="total_child" placeholder="Total Child" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <div class="mb-1">
                                      <label class="form-label" for="basic-addon-name">Coupon Code</label>
                                      <input
                                        type="text" class="form-control" name="coupon_code" v-model="form.coupon_code" placeholder="Coupon Code" @focusout="villaNumber()"/>
                                    </div>
                                    <div class="mb-1">
                                      <div class="row">
                                        <div class="col-6">
                                          <label class="form-label" for="basic-addon-name">Villa Amount</label>
                                          <input type="number" class="form-control" name="amount" v-model="form.amount" id="amount" step="any" placeholder="Amount" readonly />
                                              <!-- @keyup="form.amount.toString().length > 0
                                                  ? [(amountError = 'none'), (disabled = false)]
                                                  : (amountError = 'block')" -->
                                          <!-- <div class="invalid-feedback fw-bold fst-italic" :style="{display: amountError}" > Please enter Amount. </div> -->
                                        </div>
                                        <div class="col-6">
                                          <label class="form-label" for="basic-addon-name">Extra Charges</label>
                                          <input type="number" class="form-control" name="extra_charge" v-model="form.extra_charge" id="extra_charge" step="any" placeholder="Extra Charges" readonly />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <div class="row">
                                        <div class="col-6">
                                          <label class="form-label" for="basic-addon-name">Discount Amount</label>
                                          <input readonly type="number" class="form-control" name="discount_amount" v-model="form.discount_amount" id="discount_amount" step="any" placeholder="Discount Amount"/>
                                        </div>
                                        <div class="col-6">
                                          <label class="form-label" for="basic-addon-name">GST Amount</label>
                                          <input
                                              type="number" class="form-control" name="GST_amount" v-model="form.GST_amount" id="GST_amount" step="any" placeholder="GST Amount" readonly/>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="mb-1">
                                      <label class="form-label" for="basic-addon-name">Final Amount</label>
                                      <input
                                          type="number" class="form-control" name="final_amount" v-model="form.final_amount" id="final_amount" step="any" placeholder="Final Amount" readonly/>
                                    </div>
                                    <div class="mb-1">
                                      <label class="form-label" for="basic-addon-name">Payment Mode</label
                                      >
                                      <Select2 v-model="form.payment_mode_id" :options="$store.state.paymentModeData" :settings="{ placeholder: 'Choose Payment Mode' }" @select="paymentSelectEvent($event)"/>
                                      <div
                                        class="invalid-feedback fw-bold fst-italic"
                                        :style="{display: paymentModeError}"
                                      >
                                        Please Choose Payment Mode.
                                      </div>
                                    </div>
                                    
                                    
                                    <!-- <div class="mb-1" v-show="showCancel">
                                      <label class="form-label" for="basic-addon-name">Booking Cancel</label>
                                      <input
                                          type="date" class="form-control" name="booking_cancel" v-model="form.booking_cancel" id="booking_cancel" placeholder="Booking Cancel" @change="
                                          form.booking_cancel.length > 0
                                              ? [(dateCancelError = 'none'), (disabled = false)]
                                              : (dateCancelError = 'block')
                                          "/>
                                      <div class="invalid-feedback fw-bold fst-italic" :style="{display: dateCancelError}"> Please select Booking Cancel Date. </div>
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                              <div class="p-1 mt-2 sectionBlock">
                                <div class="row">
                                  <div class="col-12">
                                    <div class="invoice-padding invoice-product-details">
                                      <div class="source-item">
                                        <div data-repeater-list="group-a">
                                          <div v-for="(item , index) in form.persons" :key="item">
                                            <div :class="index === 0 ? `border-bottom sectionBlock mb-1 pt-1 pb-1` : (index === 1 ? `selectioBoxOther pt-1` : ``)" class="d-flex row">
                                              <h4 v-show="index === 0">Primary Member Detail</h4>
                                              <h4 v-show="index === 1">Other Member Detail</h4>
                                              <input type="hidden" name="detail_id" :id="`detail_id`+index" v-model="form.persons[index].detail_id">
                                              <input type="hidden" name="user_id" :id="`user_id`+index" v-model="form.persons[index].user_id">
                                              <input type="hidden" name="barcode_id" :id="`barcode_id`+index" v-model="form.persons[index].barcode_id">
                                              <div class="row" v-show="(index === 0)">
                                                <div class="mb-1">
                                                  <div class="float-start col-6">
                                                    <div class="input-group input-group-merge w-50 mobileSearchDiv">
                                                      <span class="input-group-text" id="basic-addon-search1"
                                                        ><vue-feather type="search"></vue-feather></span>
                                                      <input
                                                        type="number"
                                                        class="form-control search-mobile"
                                                        placeholder="Search Mobile..."
                                                        aria-label="Search..."
                                                        aria-describedby="basic-addon-search2"
                                                        v-model="search_mobile"
                                                        @click="[search_mobile_other = '', isSearchMobileOther = false]"
                                                        @keyup="searchMobile(1, $event)"
                                                      />
                                                      <div class="mobileDiv" v-show="isSearchMobile">
                                                        <ul v-if="(mobileList.length > 0)">
                                                          <template v-for="mList in mobileList" :key="mList.id">
                                                            <li @click="selectMobile(mList.id, 1)"> {{mList.mobile}} </li>
                                                          </template>
                                                        </ul>
                                                        <div class="card" v-show="loader">
                                                          <div class="card-body">
                                                              <div class="spinner-grow text-primary me-1" role="status">
                                                                  <span class="visually-hidden">Loading...</span>
                                                              </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row" v-show="(index === 1)">
                                                <div class="mb-1">
                                                  <div class="float-start col-6">
                                                    <div class="input-group input-group-merge w-50 mobileSearchDiv">
                                                      <span class="input-group-text" id="basic-addon-search2"
                                                        ><vue-feather type="search"></vue-feather></span>
                                                      <input
                                                        type="number"
                                                        class="form-control search-mobile"
                                                        placeholder="Search Mobile..."
                                                        aria-label="Search..."
                                                        aria-describedby="basic-addon-search1"
                                                        v-model="search_mobile_other"
                                                        @click="[search_mobile = '', isSearchMobile = false]"
                                                        @keyup="searchMobile(2, $event)"
                                                      />
                                                      <div class="mobileDiv" v-show="isSearchMobileOther">
                                                        <ul v-if="(otherMobileList.length > 0)">
                                                          <template v-for="omList in otherMobileList" :key="omList.id">
                                                            <li @click="selectMobile(omList.id, 2)"> {{omList.mobile}} </li>
                                                          </template>
                                                        </ul>
                                                        <div class="card" v-show="loader">
                                                          <div class="card-body">
                                                              <div class="spinner-grow text-primary me-1" role="status">
                                                                  <span class="visually-hidden">Loading...</span>
                                                              </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-sm-6">
                                                <div class="mb-1">
                                                  <label class="form-label" for="basic-addon-name">First Name</label>
                                                  <input
                                                    type="text" class="form-control" name="first_name" v-model="form.persons[index].first_name" placeholder="First Name" 
                                                    @keyup="
                                                    form.persons[index].first_name.length > 0
                                                        ? [(firstError[index] = false), (disabled = false)]
                                                        : (firstError[index] = true)
                                                    "/>
                                                  <div class="fw-bold fst-italic error" v-show="firstError[index]"> Please enter First name. </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-sm-6">
                                                <div class="mb-1">
                                                  <label class="form-label" for="basic-addon-name">Middle Name</label>
                                                  <input
                                                    type="text" class="form-control" name="middle_name" v-model="form.persons[index].middle_name" placeholder="Middle Name"/>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-sm-6">
                                                <div class="mb-1">
                                                  <label class="form-label" for="basic-addon-name">Last Name</label>
                                                  <input
                                                    type="text" class="form-control" name="last_name" v-model="form.persons[index].last_name" placeholder="Last Name" @keyup="
                                                      form.persons[index].last_name.length > 0
                                                        ? [(lastError[index] = false), (this.disabled = false)]
                                                        : (lastError[index] = true)
                                                    "/>
                                                  <div class="error fw-bold fst-italic" v-show="lastError[index]" > Please enter Last name. </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-sm-6">
                                                <div class="mb-1">
                                                  <label class="form-label" for="basic-addon-name">Mobile</label>
                                                  <input
                                                    type="number" class="form-control" name="mobile" v-model="form.persons[index].mobile" id="mobile" placeholder="Mobile" @keyup="
                                                      form.persons[index].mobile.toString().length > 0
                                                        ? [(mobileError[index] = false), (this.disabled = false)]
                                                        : (mobileError[index] = true)
                                                    "/>
                                                  <div class="error fw-bold fst-italic" v-show="mobileError[index]" > Please enter Mobile. </div>
                                                </div>
                                              </div>
                                              <div class="col-md-2 col-sm-6">
                                                <div class="mb-1">
                                                  <label class="form-label" for="basic-addon-name">Id Proof</label>
                                                  <input type="file" class="form-control" name="id_proof" ref="proofFile" placeholder="Id Proof" :id="index" @change="uploadProofImage(index, $event)"/>
                                                </div>
                                              </div>
                                              <div class="col-md-1 col-sm-6 img-view" :class="form.persons[index].id_proof ? `` : `border`">
                                                <div id="user-avatar-section">
                                                  <div id="d-flex justify-content-start" >
                                                    <img v-if="form.persons[index].id_proof" :src="form.persons[index].id_proof" class="img-fluid rounded" height="70" width="70" />
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-1 col-sm-6 trash-view">
                                                <div class="d-flex" v-if="index !== 0">
                                                  <vue-feather type="trash-2" size="20" @click="removeContent(index, form.persons[index].detail_id)"></vue-feather>&nbsp;&nbsp;
                                                </div>
                                                <span class="badge badge-light-primary rounded-pill float-end">{{index+1}}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-12">
                                                <button type="button" class="btn btn-primary btn-sm btn-add-new float-end" @click="addToContent()" data-repeater-create>
                                                    <vue-feather type="plus" size="15"></vue-feather>
                                                    <span class="align-middle">Add Person</span>
                                                </button>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>  
                                </div>
                              </div>
                              <div class="row mt-2 ">
                                <div class="col-12 text-center">
                                  <button
                                    type="submit"
                                    class="btn btn-primary me-1 data-submit "
                                    :disabled="disabled"
                                  >
                                    Submit
                                  </button>
                                  <button
                                    type="reset"
                                    class="btn btn-outline-secondary"
                                    :disabled="disabled"
                                    data-bs-dismiss="modal"
                                    @click="[showForm=false, showTable=true]"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>
  
  <script>
  import axios from "axios";
  import LaravelVuePagination from "laravel-vue-pagination";
  import Select2 from 'vue3-select2-component';
  import Form from 'vform';
  import { ref/*,onMounted*/  } from 'vue'
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import moment from 'moment'
  
  export default {
    name: "VillaBooking",
    components: {
      Pagination: LaravelVuePagination,
      Select2,
      Datepicker
    },
    data() {
      return {
        form: new Form({
            id: null,
            rangeDate: ref(),
            check_in_date: "",
            check_out_date: "",
            amount: "",
            discount_amount: "",
            coupon_code: "",
            GST_amount: "",
            extra_charge: "",
            final_amount: "",
            payment_mode_id: "",
            // booking_cancel: "",
            total_adult: "",
            total_child: "",
            total_villa: [],
            is_user_exist: false,
            persons:[{
              detail_id: this.detail_id,
              user_id: this.user_id,
              barcode_id: this.barcode_id,
              first_name: this.first_name,
              middle_name: this.middle_name,
              last_name: this.last_name,
              mobile: this.mobile,
              id_proof: this.id_proof,
              is_primary_user: true,
              
            }],  
        }),
        isActive: false,
        checkInDateError: "none",
        // amountError: "none",
        paymentModeError: "none",
        totalAdultError: "none",
        recordId: 0,
        lastError: [],
        firstError: [],
        mobileError: [],
        villa_type_id: [],
        show_villa: [],
        showVillaError: [],
        response: "",
        disabled: false,
        header: [],
        list: [],
        villaStore: [],
        modalName: "Add",
        paginationData: {},
        search: null,
        token: localStorage.getItem('auth_token'),
        showTable: true,
        showForm: false,
        showCancel: false,
        loader: false,
        search_mobile: null,
        search_mobile_other: null,
        isSearchMobile: false,
        isSearchMobileOther: false,
        mobileList: {},
        otherMobileList: {},
        sentence: null,
        searchVilla: []
      };
    },
    async mounted() {
      const startDate = new Date();
      const endDate = new Date();
      // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      this.form.rangeDate = [startDate, endDate];
      this.header = [
        "Booking Id",
        "Booking Date",
        "Check in",
        "Check out",
        "Amount",
        "Discount",
        "Counpon",
        "GST",
        "Total",
        "Final",
        "Payment",
        "Booking Type",
        "Status",
        "Cancel",
        "Adult",
        "Child",
        "Action",
      ];
      this.getResults();
      this.getVillaTypeList();
      this.$store.dispatch('getPaymentModeList');
    },
    methods: {
      async getVillaTypeList() {
        await axios.get(process.env.VUE_APP_API_URL+"/villa_type_master?page=" + 0, 
        {
          headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
          }
        }).then((response) => {
           this.villaStore = response.data.data
        });
      },
      async add() {
        this.showTable = false
        this.showForm = true
        this.showCancel = false

        this.form.id = null;
        const startDate = new Date();
        const endDate = new Date();
        this.form.rangeDate = [startDate, endDate];
        this.form.check_in_date = "";
        this.form.check_out_date = "";
        this.form.amount = "";
        this.form.extra_charge = "";
        this.form.discount_amount = "";
        this.form.coupon_code = "";
        this.form.GST_amount = "";
        this.form.final_amount = "";
        this.form.payment_mode_id = "";
        // this.form.booking_cancel = "";
        this.form.total_adult = "";
        this.form.total_child = "";
        this.form.total_villa = [];
        this.villa_type_id= [],
        this.show_villa= [],
        this.showVillaError= [],
        this.form.persons = [{
          detail_id: null,
          user_id: null,
          barcode_id: null,
          first_name: '',
          middle_name: '',
          last_name: '',
          mobile: '',
          id_proof: '',
          is_primary_user: true,
        }]  
        this.recordId= 0;
      },
      back() {
        this.showTable = true
        this.showForm = false
      },
      paymentSelectEvent({ id }) {
        this.form.payment_mode_id = id;
        this.disabled = false;
        if(this.form.payment_mode_id.length > 0) {
          this.paymentModeError = "none";
        }
      },
      uploadProofImage(index, e) {
        let imgProof = e.target.files[0];
        this.createBase64Image(imgProof, index);
      },
      createBase64Image(fileObject, index) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.persons[index].id_proof = e.target.result;
        };
        reader.readAsDataURL(fileObject);
      },
      addToContent(){
        this.form.persons.push({
          detail_id: this.form.detail_id,
          user_id: this.form.user_id,
          barcode_id: this.form.barcode_id,
          first_name: this.form.first_name,
          middle_name: this.form.middle_name,
          last_name: this.form.last_name,
          mobile: this.form.mobile,
          id_proof: this.form.id_proof,
          is_primary_user: false,
        });
        this.form.persons.detail_id = null;
        this.form.persons.user_id = null;
        this.form.persons.barcode_id = null;
        this.form.persons.first_name = '';
        this.form.persons.middle_name = '';
        this.form.persons.last_name = '';
        this.form.persons.mobile = '';
        this.form.persons.id_proof = '';
        this.form.persons.is_primary_user = '';
      },
      removeContent(idx, detailId = null) {
        if(detailId) {
          this.$swal({
          title: "Are you sure?",
          text: "You won't be able to Delete this User!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
              if (result.value) {
              axios.post(process.env.VUE_APP_API_URL+"/booking/villa/delete-user",
                {
                  detail_id: detailId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then((response) => {
                if (response.data.status) {
                  this.form.persons.splice(idx, 1);
                  this.disabled = false;
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
            }
          });
        } else {
          this.form.persons.splice(idx, 1);
          this.disabled = false;
        }
      },
      checkChange(event, id) {
        console.log(event);
        if(event.target.checked) {
          this.show_villa[id] = true
          this.showVillaError[id] = true
        } else {
          this.show_villa[id] = false
          this.showVillaError[id] = false
          this.form.total_villa[id] = 0;
          
          this.villaNumber();
        }
      },
      async getResults(page = 1) {

        await axios.get(process.env.VUE_APP_API_URL+"/booking/villa?page=" + page + "&type=",
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.paginationData = response.data.data;
          });
        this.list = this.paginationData.data;
      },
      getStatus(status) {
        if(status == 0) {
          return [`badge-light-primary`, `Progress`]
        } else if(status == 1) {
          return [`badge-light-success`, `Success`]
        } else if(status == 2) {
          return [`badge-light-warning`, `Pending`]
        } else if(status == 3) {
          return [`badge-light-danger`, `Cancel`]
        } else if(status == 4) {
          return [`badge-light-info`, `Failed`]
        }
      },
      clickStatus(id) {
        this.recordId = id
        this.isActive = !this.isActive;
      },
      async clickLi(st, id) {
        this.loader = false;
        await axios
          .post(process.env.VUE_APP_API_URL+"/booking/villa/change-status",
            {
              id: id,
              status: st
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.loader = false;
              this.paginationData = response.data.data;
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
        this.isActive = !this.isActive;
        this.list = this.paginationData.data;
      },
      dateResult() {
        const date = this.form.rangeDate;
        if(date.length > 0 && date.length != undefined) {
          date.map(() => {
            this.form.check_in_date = moment(date[0]).format('YYYY-MM-DD')
            this.form.check_out_date = moment(date[1]).format('YYYY-MM-DD')
          })
        } else {
          if(typeof date != undefined) {
            this.form.check_in_date = moment(date).format('YYYY-MM-DD')
            this.form.check_out_date = moment(date).format('YYYY-MM-DD')
          }
        }
      },
      dateChange() {
        this.dateResult();
        this.villaNumber();
        this.checkvalidity();
      },
      async checkvalidity() {
        if(this.form.coupon_code) {
          this.form.amount = '';
          this.form.discount_amount = '';
          this.form.GST_amount = '';
          this.form.final_amount = '';
        }
        this.dateResult();
        await axios
          .post(process.env.VUE_APP_API_URL+"/booking/search-villa",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.code == 200) {
              this.searchVilla = response.data.data;
              console.log(this.searchVilla);
              // this.form.amount= response.data.data.amount
              // this.form.discount_amount = response.data.data.discount_amount
              // this.form.extra_charge = response.data.data.extra_charge
              // this.form.GST_amount = response.data.data.GST_amount
              // this.form.final_amount = response.data.data.final_amount
              // this.sentence = response.data.data.sentence
            } else if(response.data.code == 400) {
              this.$refs.total_adult.focus();
              this.$toast.warning(response.data.error?.[0]);
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
      async villaNumber() {
        if(this.form.coupon_code) {
          this.form.amount = '';
          this.form.discount_amount = '';
          this.form.GST_amount = '';
          this.form.final_amount = '';
        }
        this.dateResult();
        await axios
          .post(process.env.VUE_APP_API_CUS_URL+"/villa-booking-total",
            {
                    "villa_booking": [
                        {
                            "villa_type_id": '',
                            "person": (this.form.total_adult == "") ? 0 : this.form.total_adult,
                            "check_in_date": this.form.check_in_date,
                            "check_out_date": this.form.check_out_date
                        }
                    ]
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.code == 200) {
              this.form.amount= response.data.data.amount
              this.form.discount_amount = response.data.data.discount_amount
              this.form.extra_charge = response.data.data.extra_charge
              this.form.GST_amount = response.data.data.GST_amount
              this.form.final_amount = response.data.data.final_amount
              this.sentence = response.data.data.sentence
            } else if(response.data.code == 400) {
              this.$refs.total_adult.focus();
              this.$toast.warning(response.data.error?.[0]);
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
      async selectMobile(id, searchType) {
        if(searchType == 1) {
          this.mobileList = {};
          this.isSearchMobile = false;
        } else if(searchType == 2) {
          this.otherMobileList = {};
          this.isSearchMobileOther = false;
        } 
        await axios
          .post(process.env.VUE_APP_API_URL+"/booking/villa/select-mobile",
            {
              type: searchType,
              select_id: id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            this.loader=false;
            if (response.data.status) {
              this.form.is_user_exist = true;
              if(searchType == 1) {
                this.form.persons[0].first_name = response.data.data.first_name
                this.form.persons[0].middle_name = response.data.data.middle_name
                this.form.persons[0].last_name = response.data.data.last_name
                this.form.persons[0].mobile = response.data.data.mobile
                this.form.persons[0].id_proof = response.data.data.id_proof
              }
              else if(searchType == 2) {
                var personArr = this.form.persons;
                var count = 0;
                personArr.filter((it) => (it.first_name == undefined) ? count++ : count);
                if(count > 0) {
                  var leng = this.form.persons.length - 1;
                  this.form.persons[leng].first_name = response.data.data.first_name
                  this.form.persons[leng].middle_name = response.data.data.middle_name
                  this.form.persons[leng].last_name = response.data.data.last_name
                  this.form.persons[leng].mobile = response.data.data.mobile
                  this.form.persons[leng].id_proof = response.data.data.id_proof
                } else {
                  this.form.persons.push({
                    user_id: response.data.data.id,
                    first_name: response.data.data.first_name,
                    middle_name: response.data.data.middle_name,
                    last_name: response.data.data.last_name,
                    mobile: response.data.data.mobile,
                    id_proof: response.data.data.id_proof,
                    is_primary_user: false,
                  });
                }
              } 
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
      async searchMobile(searchType, event) {
        event.preventDefault();
        
        this.loader=true;
        var search = '';
        if(searchType == 1) {
          this.mobileList = {};
          this.isSearchMobile = true;
          if (this.search_mobile && this.search_mobile.toString().length === 0) {
            this.isSearchMobile = false;
          }
          search = this.search_mobile;
        } else if(searchType == 2) {
          this.otherMobileList = {};
          this.isSearchMobileOther = true;
          if (this.search_mobile_other.toString().length === 0) {
            this.isSearchMobileOther = false;
          }
          search = this.search_mobile_other;
        } 

        await axios
          .post(process.env.VUE_APP_API_URL+"/booking/villa/search-mobile",
            {
              type: searchType,
              search_mobile: search,
              old_data: this.form.persons
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.loader=false;
              if(searchType == 1) {
                this.mobileList = response.data.data;
                this.otherMobileList = [];
              }
              else if(searchType == 2) {
                this.otherMobileList = response.data.data;
                this.mobileList = [];
              } 
            } else {
              this.loader=false;
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
      async submitForm() {
        var checkVillaError = this.showVillaError.includes(true);
        this.dateResult();
        this.disabled = true;
        /*if (this.form.amount.toString().length === 0) {
          this.amountError = "block";
        } else */if (this.form.check_in_date.length === 0 || this.form.check_out_date.length === 0) {
          this.checkInDateError = "block";
        } else if(checkVillaError) {
          this.$toast.error("Please add villa detail");
        } else if(this.form.total_adult.length > 0) {
          this.totalAdultError = "block";
        } else if(this.form.total_adult != this.form.persons.length) {
          this.$toast.error("Total Adult person and Person detail are mismatch");
          this.$refs.total_adult.focus();
        } else {
          this.paymentModeError = "none";

          var apiUrl = '';
          if (this.form.id) {
            apiUrl = process.env.VUE_APP_API_URL+"/booking/villa/update/"+this.form.id;
          } else {
            apiUrl = process.env.VUE_APP_API_URL+"/booking/villa";
          }
          await this.form.post(apiUrl,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              })
              .then((response) => {
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                  this.disabled = false;
                  this.showTable = true
                  this.showForm = false
                  this.getResults();
                } else {
                  this.$toast.error(response.data.error?.[0]);
                  this.disabled = false;
                }
              })
              .catch((error) => {
                this.$toast.error(error);
                this.disabled = false;
              });
        }
      },
      deleteVilla(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios.delete(process.env.VUE_APP_API_URL+"/booking/villa/" + id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then(response => {
                this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      },
      async edit(id) {
        this.disabled = false
        this.showTable = false
        this.showForm = true
        this.showCancel = true
        this.recordId= 0
        const output = await axios.get(process.env.VUE_APP_API_URL+"/booking/villa" +(id ? "/" + id : ""),
        {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
            },
        });
        this.form.GST_amount = output.data.data.GST_amount;
        this.form.final_amount = output.data.data.final_amount;
        this.form.amount = output.data.data.amount;
        this.form.extra_charge = output.data.data.extra_charge;
        // this.form.booking_cancel = output.data.data.booking_cancel;
        this.form.rangeDate = [output.data.data.check_in_date, output.data.data.check_out_date];
        this.form.coupon_code = output.data.data.coupon_code;
        this.form.discount_amount = output.data.data.discount_amount;
        this.form.id = output.data.data.id;
        this.form.payment_mode_id = output.data.data.payment_mode_id;
        this.form.total_adult = output.data.data.total_adult;
        this.form.total_child = output.data.data.total_child;
        this.form.persons = output.data.data.villa_edit;
        this.form.total_villa = output.data.data.total_villa;
        
        var arr = output.data.data.villa_edit;
        arr.map((value) => {
          this.villa_type_id[value.villa_type_id] = true;
          this.show_villa[value.villa_type_id] = true;
        })
      },
      async searchData() {
        await axios
          .post(process.env.VUE_APP_API_URL+"/booking/villa/search",
            {
              search: this.search,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data;
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
    },
  };
  </script>
  <style>
  .card-datatable {
    overflow-x: auto;
  }
  
  .product-box {
    border: 1px solid #ebe9f1;
    border-radius: 0.357rem;
  }
  .btn-add-new span {
    margin-top: 5px
  }
  .btn-add-new {
    display: flex;
  }
  .vue-feather--plus {
    margin: 2px 3px 0px 0px;
  }
  .img-view {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .trash-view {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .trash-view i {
    color: #fff;
    transition: all 0.2s ease;
    background-image: linear-gradient(47deg, #ea5455, #f08182);
    background-repeat: repeat;
    padding: 0.715rem 0.736rem;
    border-radius: 0.358rem;
    cursor: pointer;
  }
  .trash-view .disable {
    background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
  }
  .trash-view i:hover {
    transform: translateY(-2px);
  }
  .sectionBlock {
    box-shadow: 6px 6px 25px -8px #9e9e9e;
  }
  .error {
    color: #ea5455;
    font-size: 0.857rem;
  }
  .form-check {
    padding-left: 0.785rem !important;
  }
  .status-badge {
    position: relative;
    cursor: pointer;
  }
  .statusDiv {
    position: absolute;
    box-shadow: 6px 6px 25px -8px #9e9e9e;
    padding: 5px;
    border-radius: 10px;
    z-index: 9999;
    background-color: #fff;
  }
  .statusDiv ul, .mobileDiv ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0 !important;
  }
  .statusDiv ul li,.mobileDiv ul li {
    padding: 5px;
    cursor: pointer;
  }
  .statusDiv ul li:hover, .mobileDiv ul li:hover {
    background-color: #e3e0ff;
    font-weight: bold;
    border-radius: 5px;
  }
  .statusZindex {
    z-index: -1;
  }
  .selectioBoxOther {
    box-shadow: 2px -12px 16px -8px #dbdbdb;
  }
  .mobileSearchDiv {
    position: relative;
  }
  .mobileDiv {
    position: absolute;
    padding: 5px 10px;
    border-radius: 10px !important;
    z-index: 9999;
    top: 50px;
    height: auto;
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
    transition: all 1s;
    background-color: #fff;
    box-shadow: 1px 12px 25px 4px #9e9e9e !important;
  }
  .search-mobile {
    border-right: 1px solid #d8d6de !important;
    border-top-right-radius: 0.357rem !important;
    border-bottom-right-radius: 0.357rem !important;
  }
  .search-mobile:focus {
    border-color: #7367f0 !important;
  }
  /* .card-body, .content, .sectionBlock, input, select, span, .villaCls{
    background-color: #302a2a !important;
    color: #adabab !important; 
  } */
  </style>